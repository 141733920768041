/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "font-raleway";


$bp-xs: 400px;
$bp-small: 600px;
$bp-medium: 800px;
$bp-large: 1000px;
$bp-xl: 1400px;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// Mobile only navbar
app-drawer-header-menu {
  min-height: 60px;
  height: 60px;
  max-height: 60px;
}

// Desktop only navbar
app-desktop-sidebar-menu {
  min-width: 225px;
  width: 15vw;
  height: 95vh;
  position: fixed;
  //padding-top: 10px;
}

// Modals
.modal-wrapper {
  border-radius: 10px;
}

// Desktop .main-page (i.e. main page outside navbar and header) must size to fit the rest of the space
.main-page {
  @media (min-width: $bp-medium) {
    margin-left: auto;
    min-width: calc(100vw - 150px);
    width: 85vw;
    max-width: calc(100vw - 225px);
  }

  @media (min-width: $bp-medium) {
    background-image: radial-gradient(ellipse at top, #f6f5f3, transparent),
      radial-gradient(ellipse at bottom right, #f3eeec, transparent);
    background-color: white;
  }
}

ion-input {
  border-radius: 10px;
  // --placeholder-color: #303c6c;
}

ion-button {
  height: 40px;
  border-radius: 50px;
  font-size: 1rem;
}

ion-alert .alert-wrapper {
  .alert-head .alert-title,
  .alert-message {
    color: black;
  }
}

// Tutorial modal 
.helpModal .modal-wrapper {
  @media (min-width: 800px) {
    width: 50%;
    position: absolute;
    display: block;
  }
  @media (max-width: 799px) {
    width: 100%;
    height: 100%;
  }
}

//Image upload modal
.imageUploadModal .modal-wrapper {
  @media (min-width: 800px) {
    height: 50%;
  }
}

.routingModal .modal-wrapper{
  --ion-background-color: white !important;
}

// Keep autofill from changing the color of input field
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0px 1000px #f6f5f3 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.form-error{
  color: red;
  margin: 10px;
  text-align: center;
  font-size: 1rem;
}
