@font-face {
  font-family: 'Raleway';
  src: url('assets/fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype-variations'),
      url('assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  src: local("Raleway"),
      url('assets/fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype-variations'),
      url('assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
 }

@font-face {
    font-family: 'Merriweather';
    src: url('assets/fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
    src: local("Merriweather"),
        url('assets/fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    src: local("Merriweather"),
        url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'Sans';
    src: url('assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
    src: local("Merriweather"),
        url('assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}